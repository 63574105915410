import React, {useState} from "react";
import CONVENIOS from '../img/CONVENIOS.png';
import INVITADOS from '../img/INVITADOS.png';
import zis_convenios_logo from '../img/zis_convenios_logo.png';
import zis_invitados_logo from '../img/zis_invitados_logo.png';
import '../App.css';

function GuestConvention() {
  return (
    <div className="container mx-auto px-10 lg:px-32 py-2 lg:py-10">
        <div className="flex items-center justify-center">
            <div className="lg:w-2/3 w-full">
                <img src={zis_convenios_logo} class="object-cover w-32 m-auto"/>
                <div class="lg:w-2/3  w-full text-center lg:m-auto">
                    <p class="text-justify ">
                        Las empresas tienen convenios con negocios y establecimientos con el fin de apoyar y favorecer a sus empleados.
                        <br />
                        <br />
                        En la mayoría de las empresas los convenios se publican en algún mural, o se envían por correo, no son formas efectivas para recordar esa información. En cambio, si esa información la tienes con un clic de tu móvil, es más fácil y efectivo que disfruten de sus beneficios.
                        <br />
                        <br />
                        A través de ZIS Convenios los empleados estarán enterados de esos convenios para hacer uso de ellos.
                    </p>
                </div>
            </div>
            <div className="w-1/3 hidden lg:block">
                <img src={CONVENIOS} class=" object-cover w-2/3 m-auto"/>
            </div>
        </div>
        <div className="flex items-center justify-center mt-10">
            <div className="w-1/3 hidden lg:block">
                <img src={INVITADOS} class="object-cover w-2/3 m-auto"/>
            </div>
            <div className="lg:w-2/3 w-full">
                <img src={zis_invitados_logo} class="object-cover w-32 m-auto"/>
                <div class="lg:w-2/3  w-full text-center lg:m-auto">
                    <p class="text-justify ">
                        Con ZIS Invitados libérate de la bitácora de asistencia. Evita propagar la pandemia con el libro y la pluma definitivamente. Con esta opción de la aplicación invitas desde tu teléfono y vigilancia o recepción tiene la información: Solo necesitas hora, ubicación y nombre de la persona invitada. 
                            <br /><br />
                        Evita largas filas para que te atiendan. No es necesario que el empleado autorizado para invitar no esté en su lugar, ya tienes la invitación en tu teléfono y esta aparece en la computadora del vigilante con su nombre y hora de la cita como confirmación. 
                        <br /><br />
                        Cuando el invitado llegue a vigilancia o recepción al momento de registrar la hora de llegada, se envía un mensaje automático comunicando al empleado que su visita ha llegado. En la bitácora digital puedes agregar temperatura, placas, etc., además puedes descargar en Excel el historial diario, semanal o mensual para tu control.
                    </p>
                </div>
            </div>
        </div>
    </div>
  );
}

export default GuestConvention;
