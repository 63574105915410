

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {HomeView,ContactView,GuestConventionView, TermsAndConditionsView} from './views';

import zis_logo from '../src/img/icon_zis.png';
import './App.css';

import BOTON_APPSTORE from '../src/img/BOTON_APPSTORE.png';
import BOTON_GOOGLEPLAY from '../src/img/BOTON_GOOGLEPLAY.png';


export default function App() {

  const navButton = (e) => {
    const menu = document.querySelector("#mobile-menu");
    menu.classList.toggle("hidden"); 
  }

  return (
    <Router>
      <div>
        <nav>
          <div className="bg-white h-28 text-center">
            <img src={zis_logo} class="object-cover w-32 m-auto"/>
          </div>
          <div className="bg-gray-900">
            <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-2">
              <div className="block lg:hidden">
                <button onClick={navButton} className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
                  <svg className="fill-current h-3 w-3 text-white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                </button>
              </div>
              <div id="mobile-menu" className="w-full block flex-grow lg:flex lg:items-center lg:w-auto lg:block hidden">
                <div className="text-center lg:flex-grow">
                  <Link to="/" className="text-white font-bold block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-red-600 mx-2">
                    Inicio
                  </Link>
                  {/* <Link to="/users" className="text-white font-bold block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-red-600 mx-2">
                    Nosotros
                  </Link> */}
                  <Link to="/contacto" className="text-white font-bold block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-red-600 mx-2">
                    Contacto
                  </Link>
                  <a href="https://invitados.zis.mx/sign-in" target="_blank" className="text-white font-bold block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-red-600 mx-2">
                    Invitados
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </nav>
        <Switch>
          <Route path="/invitado">
            <GuestConventionView />
          </Route>
          <Route path="/contacto">
            <ContactView />
          </Route>
          <Route path="/terms_conditions">
            <TermsAndConditionsView />
          </Route>
          <Route path="/">
            <HomeView />
          </Route>
        </Switch>
      </div>
      <div className="bg-gray-200 p-6 flex justify-between">
        <div>
          <img src={zis_logo} class="w-32"/>
          <a href="terms_conditions">Términos y Condiciones</a>
          <p>(444) 450 3000</p>
          <p>zis@redtrack.com.mx</p>
          <p>Av. Prol. Muñoz No. 650, Los Reyes,</p>
          <p>C.P 78170 San Luis Potosí, S.L.P.</p>
        </div>
        <div>
          <a href="https://apps.apple.com/us/app/zis-zona-industrial/id1581012224" target="_blank"><img src={BOTON_APPSTORE} class="w-52"/></a>
          <a href="https://play.google.com/store/apps/details?id=com.smartkey.zics" target="_blank"><img src={BOTON_GOOGLEPLAY} class="w-52"/></a>
        </div>
      </div>
    </Router>
  );
}

function About() {
  return <h2>Nosotors</h2>;
}
