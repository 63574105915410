import ZONA_INDUSTRIAL_SERVICIOS from '../img/ZONA_INDUSTRIAL_SERVICIOS_1.jpg';
import SERVICIOS from '../img/SERVICIOS.png';
import comal_logo from '../img/comal_logo.png';
import quiq_logo from '../img/quiq_logo.png';
import zis_convenios_logo from '../img/zis_convenios_logo.png';
import zis_invitados_logo from '../img/zis_invitados_logo.png';
import directorio_empresarial_boton from '../img/directorio_empresarial_boton.png';

import {
    Link
  } from "react-router-dom";

import '../App.css';



function Home() {
  return (
    <div>
      <div class="relative overflow-hidden">
        <img src={ZONA_INDUSTRIAL_SERVICIOS} class="object-cover w-full h-full"/>
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:translate-x-0 lg:translate-y-0 text-justify lg:left-28 lg:top-28 w-2/4 text-center sm:text-left ">
          <p class="text-white lg:text-6xl font-black  text-center md:text-2xl sm:text-2xl text-xs">
            La aplicación que estaban buscando las empresas nacionales y transnacionales.
          </p>
          <div class="text-center">
            <button class="text-xs md:text-xl lg:text-2xl w-2/3 lg:w-1/3 mt-6 m-auto bg-gray-900 hover:bg-gray-700 text-white font-bold md:py-2 md:px-4 p-1  rounded-full">
              Conoce más
            </button>
          </div>
        </div>
      </div>
      <div className="bg-gray-900 text-center p-11 lg:flex">
        <div className="text-center border-b-4 lg:border-b-0 lg:border-r-4 border-red-600 ">
          <h1 class="text-white w-3/4 m-auto text-2xl flex-1">Muestra los convenios con los que cuenta la empresa.</h1>
        </div>
        <div className="text-center border-b-4 lg:border-b-0 lg:border-r-4 border-red-600 ">
          <h1 class="text-white w-3/4 m-auto text-2xl flex-1">Programa citas y visitas a la empresa.</h1>
        </div>
        <div className="text-center border-b-4 lg:border-b-0 lg:border-r-4 border-red-600 ">
          <h1 class="text-white w-3/4 m-auto text-2xl flex-1">Transporte de personal para tu empresa.</h1>
        </div>
        <div className="text-center">
          <h1 class="text-white w-3/4 m-auto text-2xl flex-1">Servicios de reparto a tu empresa.</h1>
        </div>
      </div>


      <div class="lg:relative overflow-hidden bg-gray-200 hidden lg:block" style={{height: '42vw'}}>
        <div class="hidden lg:block lg:absolute left-0 object-fill bottom-0 w-2/4 text-center">
          <img src={SERVICIOS} class="m-auto object-cover w-3/4"/>
        </div>
        <div class="absolute right-0 object-fill w-2/3 text-center p-5">
          <h2 class="text-3xl">
            A través de nuestra aplicación podrás acceder a los siguientes servicios:
          </h2>
          <div class="flex items-center justify-center">
            <Link to="/invitado" class="max-w-sm rounded bg-white overflow-hidden h-96 w-1/4 shadow-lg m-2">
              <div class="px-6 py-4">
                <img src={zis_convenios_logo} class="object-cover w-32 m-auto"/>
                <p class="text-gray-700 text-base">
                  Consulta de forma más sencilla los convenios con los que cuenta la empresa y disfruta sus beneficios.
                </p>
                <span class="text-red-600">Más información</span>
              </div>
            </Link>
            <Link to="/invitado" class="max-w-sm rounded bg-white overflow-hidden h-96 w-1/4 shadow-lg m-2">
              <div class="px-6 py-4">
                <img src={zis_invitados_logo} class="object-cover w-32 m-auto"/>
                  <p class="text-gray-700 text-base">
                    Envía invitaciones desde tu teléfono y liberate de la bitácora de asistencia y registros de invitacion.
                  </p>    
                <span class="text-red-600">Más información</span>
              </div>
            </Link>
            <a href="https://comal.club/comal/public/" target="_blank" class="max-w-sm rounded bg-white overflow-hidden h-96 w-1/4 shadow-lg m-2">
              <div class="px-6 py-4">
                <img src={comal_logo} class="object-cover w-32 m-auto"/>
                <p class="text-gray-700 text-base">
                  Pide comida y mucho más directo hasta tu empresa sin límite de distancia. 
                </p>
                <span class="text-red-600">Más información</span>
              </div>
            </a>
            <a href="https://quiq.mx/web/public/" target="_blank" class="max-w-sm rounded bg-white overflow-hidden h-96 w-1/4 shadow-lg m-2">
              <div class="px-6 py-4">
                <img src={quiq_logo} class="object-cover w-32 m-auto"/>
                <p class="text-gray-700 text-base">
                  Solicita viajes en QuiQ cualquier día y a cualquier hora para facilitar el traslado de tu personal.
                </p>
                <span class="text-red-600">Más información</span>
              </div>
            </a>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div class="overflow-hidden bg-gray-200 lg:hidden">
        <div class="right-0 object-fill text-center p-5">
          <h2 class="text-3xl">
            A través de nuestra aplicación podrás acceder a los siguientes servicios:
          </h2>
          <div class="flex flex-wrap items-center justify-center">
            <Link to="/invitado" class="max-w-sm rounded bg-white overflow-hidden shadow-lg m-2">
              <div class="px-6 py-4">
                <img src={zis_convenios_logo} class="object-cover w-32 m-auto"/>
                <p class="text-gray-700 text-base">
                  Consulta de forma más sencilla los convenios con los que cuenta la empresa y disfruta sus beneficios.
                  
                </p>
                <span class="text-red-600">Más información</span>
              </div>
            </Link>
            <Link to="/invitado" class="max-w-sm rounded bg-white overflow-hidden shadow-lg m-2">
              <div class="px-6 py-4">
                <img src={zis_invitados_logo} class="object-cover w-32 m-auto"/>
                  <p class="text-gray-700 text-base">
                  Envía invitaciones desde tu teléfono y libérate de la bitácora de asistencia y registros de invitación.
                    
                  </p>
                    <br/>     
                <span class="text-red-600">Más información</span>
              </div>
            </Link>
            <a class="max-w-sm rounded bg-white overflow-hidden shadow-lg m-2">
              <div class="px-6 py-4">
                <img src={comal_logo} class="object-cover w-32 m-auto"/>
                <p class="text-gray-700 text-base">
                Pide comida y mucho más directo hasta tu empresa sin límite de distancia. 
                </p>
                <span class="text-red-600">Más información</span>
              </div>
            </a>
            <a class="max-w-sm rounded bg-white overflow-hidden shadow-lg m-2">
              <div class="px-6 py-4">
                <img src={quiq_logo} class="object-cover w-32 m-auto"/>
                <p class="text-gray-700 text-base">
                  Solicita viajes en QuiQ cualquier día y a cualquier hora para facilitar el traslado de tu personal.
                </p>
                <span class="text-red-600">Más información</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="bg-white text-center p-11">
        <img src={directorio_empresarial_boton} class="object-cover m-auto"/>
        <div className="mt-6 m-auto md:w-96 text-center">
          <p>
            En nuestro <span className="text-red-600">directorio empresarial</span> podrás encontrar a las empresas que ya forman parte de ZIS.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
