import React, {useState} from "react";
import '../App.css';

function Contact() {
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const send = (e) => {
        e.preventDefault();
        console.log(phone);
    }
  return (
    <div>
        <div className="bg-white h-28 text-center p-8">
            <span className="text-5xl font-bold block">Contáctanos</span>
        </div>
        <div className="bg-red-600 text-center p-8">
            <form onSubmit={send} className="flex flex-col">
                <input placeholder=" Nombre" className="w-1/2 my-1 h-10 m-auto" type="text" value={name} onChange={(e)=>{setName(e.target.value)}} />
                <input placeholder=" Teléfono" className="w-1/2 my-1 h-10 m-auto" type="text" value={phone} onChange={(e)=>{setPhone(e.target.value)}} />
                <input placeholder=" Correo electrónico" className="w-1/2 my-1 h-10 m-auto" type="text" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                <textarea rows="10" placeholder=" Mensaje" className="w-1/2 my-1 h-10 m-auto h-28" type="text" value={message} onChange={(e)=>{setMessage(e.target.value)}} />
                <button type="submit" class="text-2xl w-1/5 mt-6 m-auto bg-gray-900 hover:bg-gray-700 text-white font-bold py-2 rounded-full">
                    Enviar
                </button>
            </form>
        </div>
    </div>
  );
}

export default Contact;
