
function TermsAndConditions() {
return (
    <div style={{
        'padding-left': '50px',
        'padding-right': '50px',
        'padding-top': '50px',
        'padding-bottom': '50px'
        }}>  
      
    <p><strong>Aviso de Privacidad y Confidencialidad de la
Información.</strong></p>
<p>Con fundamento en los artículos 15, 16 y demás relativos de la Ley
Federal de Protección de Datos Personales en Posesión de los
Particulares en adelante la (“Ley”), su reglamento y demás normativa
aplicable, La empresa SMART KEY S.A de C.V., con domicilio ubicado en:
Av. Muñoz No. 650, Plaza Muñoz, Local K1, Col. Los Reyes, C.P. 78170,
San Luis Potosí, S.L.P., en adelante la (la “Empresa” o “ZIS”), e la
empresa responsable de recabar, uso y protección y el tratamiento de sus
datos personales, está comprometida a resguardar su información personal
con seguridad legal, tecnológica y administrativa; así mismo, a no
vender, comercializar, alquilar, compartir o divulgar su información
personal a terceros con fines ilícitos o contrarios a los de su titular
o lo establecido en el presente aviso de privacidad. Obtendrá
directamente de Usted los datos personales que sean necesarios para la
adecuada prestación de los servicios que Usted solicite. La privacidad
de la información de los usuarios es muy importante por esa razón que se
toman las precauciones y recaudos para resguardar su información,
utilizando los mecanismos de seguridad informática de protección de la
información más completos y eficaces. Este documento es parte integrante
de los Términos y condiciones generales de la Empresa. Mediante la
aceptación de los Términos y condiciones generales en el momento de la
inscripción el usuario o cliente o terceros como titulares de sus datos
personales, aceptan las disposiciones aquí contenidas. Asimismo, una vez
inscrito podrá revisar y cambiar la información que nos ha enviado
durante el proceso de inscripción incluyendo:</p>
<ul>
<li><blockquote>
<p>El nombre y dirección de correo electrónico. Sin perjuicio de los
cambios que realice, la Empresa conservará los datos personales
anteriores por motivos de seguridad y control del fraude.</p>
</blockquote></li>
<li><blockquote>
<p>La información de la inscripción como: compañía, domicilio, ciudad,
región, código postal, número principal de teléfono, número secundario
de teléfono, número de fax, correo electrónico.</p>
</blockquote></li>
<li><blockquote>
<p>La clave.</p>
</blockquote></li>
</ul>
<p>Los usuarios deben actualizar sus datos personales periódicamente
conforme vaya cambiando para que los otros usuarios puedan ubicarlos
cuando realicen una operación. Para hacer cualquier modificación en la
información que, suministrada en el momento de la Inscripción, se debe
ingresar en la sección correspondiente para su actualización. Para
recibir mayor información sobre la confidencialidad de tus datos
personales, así como para el caso que no desees recibir información de
la Empresa y/o publicidad, contáctenos por correo electrónico o postal
al departamento de administración a quien podrás contactar por correo
electrónico: <a href="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="71081e021e0831031415050310121a5f121e1c5f1c09">[email&#160;protected]</a>, o por correo dirigido a: Av. Muñoz
No. 650, Plaza Muñoz, Local K1, Col. Los Reyes, C.P. 78170, San Luis
Potosí, S.L.P.</p>
<p>Los datos que recabamos. Lo primero que se debe hacer para disfrutar
de los servicios de la Empresa es inscribirse, suministrando ciertos
datos personales ("Datos personales") completos y exactos. Podremos
solicitar, recabar y almacenar los siguientes datos personales: nombre,
identificación válida, información de contacto (como número de teléfono,
domicilio, dirección de e-mail), genero, edad.</p>
<p>La Empresa en general no recabará de sus usuarios, prestadores,
proveedores, establecimientos afiliados, distribuidores, sucursales,
datos personales considerados sensibles por la ley. Y solo en caso de
ser requerido recaba datos considerados sensibles por requerimiento de
algún servicio específico o por requerimiento de alguna autoridad, será
bajo la autorización expresa y por escrito del titular de conformidad
con la Ley.</p>
<p>Datos personales sensibles. La Empresa puede recabar en caso de ser
necesario para algún trámite o por cumplimiento de alguna normativa
aplicable, recaba y trata datos que son o pueden ser considerados
sensibles como: datos de salud, afiliación sindical, datos de análisis
de riesgo, biométricos de conformidad con la normativa aplicable, los
datos sensibles serán tratados con la mayor seguridad y considerados
como información confidencialidad y reservada y solo podrá ser utilizado
con el consentimiento expreso y por escrito del titular.</p>
<p><em>□ Consiento que mis datos personales incluyendo los datos
personales considerados sensibles sean tratados conforme a los términos
y condiciones del aviso de privacidad.</em></p>
<p>La Empresa podrá confirmar los datos personales suministrados,
incluyendo (datos financieros y/o patrimoniales en el caso de pagos con
tarjeta de crédito o cuentas para pago de los servicios que dichos pagos
se realizan a través de la pasarela de pagos correspondiente) o los
medios de pago autorizados en la entidad correspondiente, con la
autorización expresa del titular. La información que se obtenga de estas
entidades será tratada en forma confidencial. Se acuerda expresamente
que en cualquier momento el titular podrá solicitar la baja o cierre de
su solicitud de registro, y/o el cierre de su cuenta o eliminación de su
cuenta e información de la base de datos.</p>
<p>Finalidad del tratamiento de los datos personales.</p>
<p>En los términos del artículo 16 fracción II de La Ley, los artículos
14, 30, 40, 41 y 42 del su reglamento y el artículo vigésimo cuarto de
los Lineamientos del aviso de privacidad, los datos personales que nos
proporcione serán utilizados para suministrar un excelente servicio y
para que los usuarios puedan realizar operaciones en forma ágil y
segura, para las siguientes finalidades:</p>
<ul>
<li><p>Mejorar nuestras iniciativas comerciales y promocionales y
analizar las páginas visitadas y las búsquedas realizadas por los
Usuarios, para mejorar nuestra oferta de contenidos y artículos,
personalizar dichos contenidos, su presentación y servicios.</p></li>
<li><p>Enviar información o mensajes por e-mail sobre nuevos servicios,
mostrar publicidad o promociones, banners, de interés para nuestros
usuarios, noticias sobre la Empresa o mercancías o productos, además de
la información expresamente autorizada en la sección de
preferencias.</p></li>
<li><p>Dirección de e-mail e información patrimonial y/o financiera con
los proveedores de servicios para realizar los pagos correspondientes de
los servicios y/o productos solicitados por el usuario y cliente,
prestadores o las empresas que contribuyan a mejorar o facilitar las
operaciones.</p></li>
<li><p>Suministrar los datos personales de los usuarios a las entidades
que intervengan en la resolución de disputas entre los mismos, tales
como: Tribunales o entidades públicas o privadas competentes para
solucionar tales disputas que sean legalmente solicitados por
autoridades.</p></li>
<li><p>Fines de identificación personal.</p></li>
<li><p>Para eventualmente contactarlo con el fin de establecer
comunicación directa en el esclarecimiento de nuestros procesos
internos;</p></li>
<li><p>Proveer servicios y la venta de productos requeridos;</p></li>
<li><p>Actualizar y conservar expedientes y datos internos;</p></li>
<li><p>Realizar facturación y recibos por servicios y venta, gestiones
de cobro;</p></li>
<li><p>Difusión de información en la página web y Herramienta de la
Empresa.</p></li>
<li><p>Desarrollar estudios internos sobre los intereses,
comportamientos y demografía de los usuarios para comprender mejor sus
necesidades e intereses y ofrecer mejores servicios y productos o
proveerles información relacionada.</p></li>
<li><p>Dar cumplimiento a obligaciones contractuales contraídas con
nuestros usuarios y clientes, prestadores de servicios y
proveedores;</p></li>
<li><p>Dar atención y respuesta a cualquier sugerencia o queja del
titular a su entera satisfacción.</p></li>
</ul>
<p>Intermediar entre los usuarios prestadores de servicios de
intermediación y establecimientos a entrar en contacto directo en la
oportunidad que corresponda para la compra de productos o mercancías de
los establecimientos afiliados que se comercializan disponibles a través
de la herramienta y página de la Empresa. para lo cual se suministrarán
a los interesados sus datos personales (nombre, teléfonos, localidad y
correo electrónico) a través de correo electrónico o a través del sitio.
La información así conocida sólo podrá ser utilizada a efectos de
concluir la operación originada y no deberá ser empleada por ninguno de
ellos con fines publicitarios o promocionales u otras actividades no
relacionadas con la Empresa y los Servicios, salvo la expresa
autorización del usuario. Asimismo, en el caso de utilizar la
herramienta de invitados como apoyo para la mejor atención de la
compraventa de productos solicitados por los usuarios y/o sus
visitantes, mejora en el acceso, toda esta información se mantendrá como
información confidencial y reservada por parte de la Empresa y
únicamente podrá ser utilizada para los fines descritos en el presente
aviso de privacidad.</p>
<p><strong>Finalidades secundarias</strong></p>
<p>De manera adicional, en algunos casos, para finalidades secundarias,
que nos permiten y facilitan brindarle una mejor atención: i) Enviar
promociones ii) Informar sobre cambios o nuevos servicios y productos.
En caso de que no desee recibir promociones o publicidad, puede hacerlo
contactándonos mediante un escrito o enviando un correo electrónico al
departamento de administración correo electrónico: <a href="/cdn-cgi/l/email-protection#2c55435f43556c5e4948585e4d4f47024f4341024154"><span class="__cf_email__" data-cfemail="047d6b776b7d44766160707665676f2a676b692a697c">[email&#160;protected]</span></a> al
Departamento de Administración, o dando de baja en su dispositivo dichas
notificaciones o en su correo electrónico. En el entendido que
únicamente será enviada información o publicidad a los ususarios
registrados en la herramienta y que lo hayan aceptado previamente.</p>
<p>Confidencialidad de los datos personales. Una vez inscrito en la
Herramienta, la Empresa se compromete a dar un uso adecuado a los datos
personales y mantenerlos como información confidencial y reservada,
incluyendo los datos de usuarios clientes, invitados en caso de utilizar
la herramienta de invitados que serán conservados con la mayor
confidencialidad e información. Sin perjuicio de ello, la Empresa
transfiera total o parcialmente los datos personales a cualquiera de las
sociedades afiliadas, y/o vinculadas con la Empresa únicamente para la
prestación de los servicios, venta de productos que Usted solicita,
establecimientos afiliados, prestadores de servicios de intermediación
no subordinada para reparto. En caso de que Usted no desee que sus datos
personales sean trasferidos, puede manifestar su negativa enviando un
correo electrónico o un escrito dirigido al departamento de
administración encargado de la protección de datos personales de la
Empresa, conforme más adelante se establece y especifica.</p>
<p>Clave personal de acceso. Los usuarios y clientes deben mantener esta
clave bajo absoluta confidencialidad y, en ningún caso, deberán
revelarla o compartirla con otras personas. El usuario será responsable
de todos los actos que tengan lugar mediante el uso de su nombre y/o
seudónimo y clave, lo que incluye hacerse cargo del pago de costos que
eventualmente se devenguen o por los perjuicios que puedan sufrir otros
usuarios por tal motivo. Si por cualquier razón un usuario creyera que
alguien puede conocer su clave, deberá modificarla ingresando a la
sección Datos personales.</p>
<p>Menores de edad. Nuestros servicios sólo están disponibles para
aquellas personas que tengan capacidad legal para contratar. Por lo
tanto, aquellos que no cumplan con esta condición deberán abstenerse de
suministrar datos personales para ser incluidos en nuestras bases de
datos. Sin embargo, pueden hacerlo a través de los padres o tutores.</p>
<p>Cookies. La Empresa recoge y almacena automáticamente cierta
información sobre la actividad de los usuarios y visitantes mediante
cookies de seguimiento con fines estadísticos y de remarketing. El
usuario y el visitante conoce y acepta que se podrá utilizar un sistema
de seguimiento mediante la utilización de cookies (las "Cookies"). Estas
Cookies son pequeños archivos que se instalan en el disco rígido, con
una duración limitada en el tiempo que ayudan a personalizar los
servicios. También ofrecemos ciertas funcionalidades que sólo están
disponibles mediante el empleo de Cookies. Las Cookies se utilizan con
el fin de conocer los intereses, el comportamiento y la demografía de
quienes visitan o son usuarios y de esa forma, comprender mejor sus
necesidades e intereses y darles un mejor servicio o proveerle
información relacionada. También usaremos la información obtenida por
intermedio de las Cookies para analizar las páginas navegadas por el
visitante o usuario, las búsquedas realizadas, mejorar nuestras
iniciativas comerciales y promocionales, mostrar publicidad o
promociones, banners de interés, noticias sobre proveedores, productos,
perfeccionar nuestra oferta de contenidos y artículos, personalizar
dichos contenidos, presentación y servicios; también podremos utilizar
Cookies para promover y hacer cumplir las reglas y seguridad del sitio.
Se podrá agregar Cookies en los e-mails que envíe para medir la
efectividad de las promociones. Usted puede configurar su navegador para
que no los acepte, así mismo utilizar configuraciones que no permitan
que su información sea visible a terceros. Utilizamos adicionalmente las
Cookies para que el Usuario no tenga que introducir su clave tan
frecuentemente durante una sesión de navegación, también para
contabilizar y corroborar las inscripciones, la actividad del usuario,
siempre teniendo como objetivo de la instalación de las Cookies, el
beneficio del Usuario que la recibe, y no será usado con otros fines
ajenos a los Servicios. Se establece que la instalación, permanencia y
existencia de las Cookies en el computador del usuario depende de su
exclusiva voluntad y puede ser eliminada cuando el usuario así lo desee.
Adicionalmente, se pueden encontrar Cookies u otros sistemas similares
instalados por terceros en ciertas páginas. Por ejemplo, al navegar por
una página creada por un usuario, puede que exista una Cookie emplazada
en tal página. Se aclara expresamente que este Aviso de Privacidad cubre
la utilización de Cookies por este sitio y no la utilización de Cookies
por parte de anunciantes. La Empresa no controla el uso de Cookies por
terceros.</p>
<p>Transferencia de datos. En la recopilación y tratamiento de dicha
información la Empresa<strong>,</strong> se compromete y obliga a
observar y cumplir los principios de <em>licitud, consentimiento,
información, calidad, finalidad, lealtad, proporcionalidad y
responsabilidad;</em> esto es, todos los datos que le solicitamos son
recabados de manera lícita conforme a la Ley, con el consentimiento de
Usted, recopilando única y exclusivamente los datos, pertinentes,
correctos, actualizados y necesarios para el fin único de cumplir con
los servicios y venta de productos que solicita. Por lo que la Empresa
no cede o transfiere sus datos personales a terceros ajenos al mismo,
sin su consentimiento previo. Sin embargo, puede transferir sus datos
personales a filiales del grupo y/o establecimientos afiliados,
prestadores de servicios, proveedores relacionados con los servicios o
productos y/o terceros, distribuidores o sucursales, cuando dicha
transferencia esté prevista en la Ley o sea necesario únicamente para
prestar los servicios o venta de productos que Usted solicita. Si nos
proporciona sus datos daremos por entendido que está de acuerdo con los
términos de este aviso de privacidad por lo que si Usted proporciona sus
datos y no manifiesta su oposición para que sus datos personales sean
transferidos se entenderá que ha otorgado su consentimiento para ello.
Nos comprometemos a no transferir su información personal a terceros sin
su consentimiento, salvo las excepciones previstas en el artículo 37 de
la Ley. Cláusula de aceptación o negativa de transferencia de datos. En
caso de que no desee que sus datos personales sean trasferidos, puede
manifestar su negativa enviando un correo electrónico o un escrito
dirigido al Departamento de Administración encargado de la protección de
datos personales conforme más adelante se establece y
especifica<strong>.</strong></p>
<p>Orden de autoridades competentes - Requerimientos legales. La Empresa
coopera con las autoridades competentes y con otros terceros para
garantizar el cumplimiento de las leyes, por ejemplo, en materia de
protección de derechos de propiedad industrial e intelectual, prevención
del fraude y otras materias. La Empresa podrá revelar los datos
personales de sus usuarios o prestadores de servicios no subordinados de
reparto, bajo requerimiento de la autoridades judiciales o
gubernamentales competentes para efectos de investigaciones conducidas
por ellas, entre otros, cuando se trate de investigaciones de carácter
penal o de fraude o las relacionadas con piratería informática o la
violación de derechos de autor, suplantación de identidad. En tales
situaciones, la Empresa colaborará con las autoridades competentes con
el fin de salvaguardar la integridad y la seguridad de la comunidad y la
de sus usuarios. Además, la Empresa se reserva el derecho (<em>y nos
autoriza el Usuario expresamente a ello</em>) de comunicar información
sobre sus usuarios a otros usuarios, entidades o terceros únicamente
cuando haya motivos suficientes para considerar que la actividad de un
usuario sea sospechosa de intentar o cometer un delito o intentar
perjudicar a otras personas. Este derecho será utilizado por la Empresa
a su entera discreción cuando lo considere apropiado o necesario para
mantener la integridad y la seguridad de la Comunidad y la de sus
usuarios, invitados, para hacer cumplir los Términos y Condiciones y
demás políticas del sitio y a efectos de cooperar con la ejecución y
cumplimiento de la ley. Este derecho será ejercido por la Empresa a fin
de garantizar la seguridad, confidencialidad y protección de los datos
personales. Seguridad y medios para evitar el uso o divulgación de los
datos personales.</p>
<p>Almacenamiento y medidas de protección y confidencialidad de los
datos personales. La Empresa está obligada a cumplir con toda la
normativa aplicable en materia de medidas de seguridad con todas las
medidas <em>administrativas, técnicas y físicas,</em> aplicables a la
protección de sus datos personales, adicionalmente, La Empresa usa los
estándares de la industria entre materia de protección de la
confidencialidad de sus datos personales. La Empresa no se hace
responsable por interceptaciones ilegales o violación de sus sistemas o
bases de datos por parte de personas no autorizadas, tampoco se hace
responsable por la indebida utilización de la información obtenida por
esos medios. Pero cuenta con los sistemas de protección actualizados
para evitarlo y sistemas antivirus actualizados.</p>
<p>De Otros Medios para Limitar el Uso o Divulgación de los Datos. Para
limitar el tratamiento de los Datos, la Empresa únicamente tendrá los
medios señalados en el presente Aviso de Privacidad y no enviará
información en caso de que usted no lo desee. Usted tiene a efecto de
estar más protegido opciones a su elección de acudir a instancias
administrativas o solicitar por la vía correspondiente su inscripción al
Registro Público de Consumidores previsto por la Ley Federal de
Protección al Consumidor y/o al Registro Público de Usuarios previsto
por la Ley de Protección y Defensa al Usuario de Servicios Financieros,
a efecto de limitar el uso de los datos personales en posesión de los
particulares, en caso de así considerarlo.</p>
<p>Del Tratamiento de sus Datos y su Negativa. En la recopilación y
tratamiento de dicha información la Empresa, se compromete y obliga a
observar y cumplir los principios de <em>licitud, consentimiento,
información, calidad, finalidad, lealtad, proporcionalidad y
responsabilidad</em>; esto es, todos los datos que le solicitamos son
recabados de manera lícita conforme a la Ley, con el consentimiento de
Usted, recopilando única y exclusivamente los datos, pertinentes,
correctos, actualizados y necesarios para el fin único de cumplir con
los servicios y venta que solicita durante el término y finalidades
señaladas en el presente Aviso de Privacidad. La Empresa se compromete a
contar con las medidas legales y de seguridad suficiente y necesaria
para garantizar que sus Datos permanezcan confidenciales y seguros. A
efecto de que el usted pueda manifestar su negativa respecto del
tratamiento de sus datos personales, podrá dirigirse a la Empresa
conforme lo establecido más adelante en el presente Aviso de Privacidad.
La Empresa cuenta con convenios de confidencialidad con proveedores,
prestadores de servicios no subordinados de reparto, personal en la
Empresa.</p>
<p>Medios para ejercer los Derechos ARCO (Acceso, Rectificación,
Cancelación y Oposición). Usted tiene derecho a acceder a sus datos
personales que poseemos y a los detalles del tratamiento de los mismos,
así como a rectificarlos en caso de ser inexactos o incompletos,
cancelarlos en caso de que no se requieran para alguna de las
finalidades señaladas en el presente aviso de privacidad, estén siendo
utilizados para finalidades no consentidas, haya finalizado la relación
contractual o de servicio o bien oponerse al tratamiento de los mismos
para fines específicos. Para ejercer alguno de los derechos ARCO, podrá
enviar un correo electrónico o escrito dirigido a la dirección del
Proveedor que más adelante se especifica.</p>
<p>Cláusula de limitación o solicitud de revocación de consentimiento.
En caso de que Usted desee limitar o revocar el consentimiento para el
tratamiento de sus datos personales, ejercer sus derechos ARCO o la no
transferencia de sus datos, o para cualquier aclaración de cualquier
duda puede en cualquier momento solicitarlo, podrá hacerlo
contactándonos:</p>
<p>Donde y como contactarnos: Para hacer valer los derechos ARCO o en el
caso de negativa de transferencia de datos o para dar de baja el
servicio limitar el uso o revocar el consentimiento, Empresa y/o
publicidad, contáctenos por correo electrónico o postal al departamento
de administración a quien podrás contactar por correo electrónico: <a href="/cdn-cgi/l/email-protection#bcc5d3cfd3c5fcced9d8c8cedddfd792dfd3d192d1c4"><span class="__cf_email__" data-cfemail="552c3a263a2c15273031212734363e7b363a387b382d">[email&#160;protected]</span></a>, o por
correo dirigido a: Av. Muñoz No. 650, Plaza Muñoz, Local K1, Col. Los
Reyes, C.P. 78170, San Luis Potosí, S.L.P., al departamento de
Administración, dicha solicitud debe contener los siguientes datos:</p>
<ul>
<li><p>Nombre del titular o representante legal;</p></li>
<li><p>Domicilio y correo electrónico del titular para comunicarle la
respuesta de su solicitud;</p></li>
<li><p>Documento que acredite su identidad y en su caso la personalidad
del representante del titular;</p></li>
<li><p>Manifestar de manera clara y precisa lo que requiere en su
solicitud para el tratamiento de sus datos personales;</p></li>
<li><p>Cualquier otro elemento o documento que facilite la localización
de los datos personales.</p></li>
</ul>
<p>En el término, plazo y en las condiciones establecidas por la Ley
atenderemos su petición y le informaremos por escrito dentro de los 20
días siguientes a la fecha de que sea recibida su solicitud, a través
del correo electrónico o domicilio que nos proporcione para tal efecto.
Igualmente se atenderá cualquier duda, aclaración o comentario que usted
tenga con relación a lo anterior.</p>
<p>Procedimiento para comunicar los cambios al Aviso de privacidad</p>
<p>Modificaciones al Aviso de Privacidad. Nos reservamos el derecho de
efectuar en cualquier momento modificaciones o actualizaciones al
presente aviso de privacidad de conformidad con la Ley. Estas
modificaciones estarán disponibles a través de los siguientes medios:
(i) anuncios visibles en nuestros establecimientos o sucursales; o (ii)
en nuestra página: <a
href="https://www.zis.mx/terms_conditions">https://www.zis.mx/terms_conditions</a>
sección protección de datos o (iii) se las haremos llegar al último
correo electrónico que nos haya proporcionado.</p>
<p>La fecha de la última actualización al presente aviso de privacidad:
[07/Dic/2021]</p>
<p>SMART KEY, S.A. de C.V. / ZIS</p>
</div>
);

}

export default TermsAndConditions;
